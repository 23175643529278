import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreService } from "./core/core.service";
import { AuthInterceptorService } from "./shared/auth/auth-interceptor.service";
import { ErrorInterceptor } from "./shared/auth/error-interceptor.service";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { PosthogService } from "./core/posthog.service";
import { ReactiveFormsModule } from "@angular/forms";
import { PageTrackingDirective } from "./core/directives/page-tracking.directive";
import { BluetoothService } from "./core/bluetooth.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [AppComponent, PageTrackingDirective],
  imports: [
    // BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    IonicModule.forRoot({ mode: "ios", innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    HttpClientJsonpModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
  ],
  providers: [
    CoreService,
    BluetoothService,
    PosthogService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
